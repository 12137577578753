import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
/**
 * Хук для адаптивной верстки
 * @param query - тип запроса
 * @param start - начальное значение для запроса
 * @param end - конечное значение для запроса
 */
export function useResponsive(query, start, end) {
    const theme = useTheme();
    const getQuery = useMemo(() => {
        switch (query) {
            case 'up':
                return theme.breakpoints.up(start);
            case 'down':
                return theme.breakpoints.down(start);
            case 'between':
                return theme.breakpoints.between(start, end);
            case 'only':
                return theme.breakpoints.only(start);
            default:
                return theme.breakpoints.up('xs');
        }
    }, [theme, query, start, end]);
    const mediaQueryResult = useMediaQuery(getQuery);
    return mediaQueryResult;
}
/**
 * Отслеживание фокуса на странице
 */
export const useFocus = () => {
    const [isFocused, setIsFocused] = useState(document.hasFocus());
    useEffect(() => {
        /**
         * Коллбэк при срабатывании фокуса
         */
        const onFocus = () => setIsFocused(true);
        /**
         * Коллбэк при срабатывании блюра
         */
        const onBlur = () => setIsFocused(false);
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        // Очистка при размонтировании компонента
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);
    return isFocused;
};
