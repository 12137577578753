import React from 'react';
import Box from '@mui/material/Box';
import ReplyIcon from '@mui/icons-material/Reply';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'redux/hooks';
import { useTheme } from '@mui/material';
/**
 * Компонент с текстом сообщения и количеством непрочитанных сообщений
 * @param root0 - пропсы
 * @param root0.unreadCount - количество непрочитанных сообщений
 * @param root0.lastMessageText - текст последнего сообщения
 * @param root0.lastMessageAuthorId - id пользователя, отправившего последнее сообщение
 * @param root0.isChatSelected - выбран ли чат
 */
export const ChatsNavigationMessageTextWithUnreadCount = ({ lastMessageText, unreadCount, lastMessageAuthorId, isChatSelected }) => {
    const { palette } = useTheme();
    const { data: userData } = useAppSelector(state => state.user);
    const isLastMessageIsMine = userData.id === lastMessageAuthorId;
    return (React.createElement(Box, { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 },
        React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1, width: unreadCount > 0 ? '85%' : '100%' },
            lastMessageText && (React.createElement(Box, { display: 'flex', sx: { transform: isLastMessageIsMine ? 'scaleX(-1)' : '' }, justifyContent: 'center', alignItems: 'center' },
                React.createElement(ReplyIcon, { sx: { color: isChatSelected ? palette.common.white : palette.blue[700] } }))),
            React.createElement(Typography, { color: isChatSelected ? palette.common.white : palette.blue[700], variant: 'body1', fontWeight: 500, noWrap: true, textOverflow: 'ellipsis' }, lastMessageText || 'Напишите что-нибудь...')),
        unreadCount > 0 && (React.createElement(Box, { display: 'flex', height: 22, width: 24, bgcolor: palette.primary.main, alignItems: 'center', justifyContent: 'center', borderRadius: 4 },
            React.createElement(Typography, { variant: 'body1', color: palette.common.white, fontWeight: 600 }, unreadCount)))));
};
