import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useAppSelector } from 'redux/hooks';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ChatsContext } from 'components/chats/Context';
import { useChannel } from 'functions/Websocket';
import { apiUrls } from 'config/api';
import { CHAT_TYPES } from 'components/chats/navigation/List';
import { getChats } from './getChats';
/**
 * Хук для получения данных для чатов
 */
export const useChats = () => {
    const [chatsListUrl, setChatsListUrl] = useState('');
    const [activeTab] = useQueryParam('tab', StringParam);
    const [, setChatId] = useQueryParam('chat_id', StringParam);
    const { setSelectedChatUnreadCount } = useContext(ChatsContext);
    const { auth: authData, data: userData } = useAppSelector(state => state.user);
    const [chats, setChats] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { data, error, isFetching } = useQuery({
        queryKey: [chatsListUrl, page],
        queryFn: getChats,
        enabled: !!chatsListUrl,
        refetchOnWindowFocus: false
    });
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (data) {
            if (data.pagination?.pages)
                setTotalPages(data.pagination.pages);
            const isFirstPage = page === 1;
            setChats(prev => (isFirstPage ? data.chats : [...prev, ...data.chats]));
        }
    }, [data]);
    useEffect(() => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }, [error]);
    useEffect(() => {
        let newChatsListUrl = '';
        if (activeTab === CHAT_TYPES.unassigned)
            newChatsListUrl = apiUrls.chats.list;
        if (activeTab === CHAT_TYPES.operators)
            newChatsListUrl = apiUrls.chats.operators.list;
        if (activeTab === CHAT_TYPES.archive)
            newChatsListUrl = apiUrls.chats.archive.list;
        if (newChatsListUrl !== chatsListUrl) {
            setChatsListUrl(newChatsListUrl);
        }
    }, [activeTab]);
    /**
     * Обработка получения сообщения из сокета
     * @param chanelData - данные канала
     */
    const chatsChannelReceiveAction = (chanelData) => {
        const { chat_data: chatData } = chanelData;
        if (chatData) {
            if (chatData.unread_count > 0) {
                // Обновляем массив чатов новыми данными
                setChats((prev) => {
                    const updatedChat = prev.find(chat => chat.id === chatData.chat_id);
                    if (updatedChat) {
                        const updatedChats = prev.filter(chat => chat.id !== updatedChat.id);
                        return [{ ...updatedChat, ...chatData }, ...updatedChats];
                    }
                    return prev;
                });
            }
            else {
                setChats(prev => prev.map((chat) => {
                    if (chat.id === chatData.chat_id)
                        return { ...chat, unread_count: chatData.unread_count };
                    return chat;
                }));
            }
            // Если в текущем открытом чате появились непрочитанные сообщений - заносим их число в контекст
            // setChatId используется для получения актуального id
            setChatId((currentChatId) => {
                if (currentChatId === chatData.chat_id) {
                    setSelectedChatUnreadCount(chatData.unread_count);
                }
                return currentChatId;
            });
        }
    };
    const { handleJoinChannel, isChannelConnectionFailed } = useChannel({
        channel: 'Chats::UserChannel',
        token: authData?.['access-token'],
        client: authData?.client,
        id: userData.id
    }, chatsChannelReceiveAction);
    // Подключение к каналу
    useLayoutEffect(() => {
        const channel = handleJoinChannel();
        return () => {
            channel?.unsubscribe();
        };
    }, []);
    // Увеличение страницы
    const increasePage = useCallback(() => {
        if (totalPages > page && page === data?.pagination.page)
            setPage((prev) => prev + 1);
    }, [totalPages, page, data]);
    // Обработка ошибки подключения к каналу
    useEffect(() => {
        if (isChannelConnectionFailed) {
            enqueueSnackbar('Ошибка подключения к чатам', { variant: 'error' });
        }
    }, [isChannelConnectionFailed]);
    const memoizedValue = useMemo(() => ({
        chats,
        isLoading: isFetching && !chats.length,
        isLoadingAddItems: isFetching && !!chats.length,
        increasePage
    }), [
        chats, isFetching, increasePage
    ]);
    return memoizedValue;
};
