import React from 'react';
import CardsBase from 'components/cards/Base';
import Loader from 'helpers/Loader';
const fields = {
    birthday: {
        title: 'Дата рождения',
        key: 'birth_date'
    },
    gender: {
        title: 'Пол',
        key: 'gender'
    },
    phone: {
        title: 'Номер телефона',
        key: 'mobile_phone'
    },
    firstVisit: {
        title: 'Первое посещение',
        key: 'first_treat_date'
    }
};
/**
 * Компонент информации о пациенте в шапке страницы пациента
 * @param root0 Пропсы
 * @param root0.data Данные клиента
 */
const PatientsShowHeaderInfo = ({ data }) => {
    /**
     * Вывод поля информации о пациенте
     * @param root0 Пропсы поля
     * @param root0.title Заголовок
     * @param root0.key Ключ
     * @param value Значение поля
     */
    const renderField = ({ title, key }, value) => {
        let textToShow = value;
        if (key === 'gender') {
            if (value === 'Male')
                textToShow = 'муж';
            else if (value === 'Female')
                textToShow = 'жен';
        }
        return (React.createElement("div", { key: key },
            React.createElement("div", { className: 'text-smaller text-gray-main mb-2' }, title),
            React.createElement("div", { className: 'text-small' }, textToShow || '—')));
    };
    /**
     * Вывод основного контента
     */
    const renderContent = () => (React.createElement(CardsBase, { className: 'p-6 h-[194px] w-[384px] flex flex-col' }, data ? (React.createElement("div", { className: 'flex flex-wrap gap-x-7 gap-y-5' }, Object.values(fields).map(field => renderField(field, data.client[field.key])))) : (React.createElement(Loader, { className: 'h-full' }))));
    return renderContent();
};
export default PatientsShowHeaderInfo;
