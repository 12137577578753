// ----------------------------------------------------------------------
const MuiCard = {
    styleOverrides: {
        root: ({ theme }) => ({ boxShadow: theme.shadows[0] })
    }
};
const MuiCardContent = {
    /**
     * **************************************
     * STYLE
     ***************************************
     */
    styleOverrides: {
        root: ({ theme }) => ({ padding: theme.spacing(5) })
    }
};
export const card = {
    MuiCard,
    MuiCardContent
};
