import { useEffect, useRef, useState } from 'react';
import { createConsumer } from '@rails/actioncable';
import { useAppSelector } from 'redux/hooks';
import { socketUrl } from 'config/api';
/**
 * Получение экземпляра consumer для ActionCable
 * @param id - ID пользователя
 */
const getConsumer = (id) => {
    const { host, protocol } = window.location;
    const isSecure = protocol === 'https:';
    const socketProtocol = isSecure ? 'wss:' : 'ws:';
    return createConsumer(`${socketProtocol}//${host}${socketUrl}?id=${id}`);
};
// Время ожидания подключения к каналу
const reconnectionBreak = 16000;
/**
 * Хук для подключения к каналу
 * @param channelOptions - Параметры канала
 * @param receivedAction - Коллбэк функция, которая вызывается при получении данных с канала
 */
export const useChannel = (channelOptions, receivedAction = () => { }) => {
    const { data: userData } = useAppSelector(state => state.user);
    const consumerRef = useRef(getConsumer(userData.id));
    const timeoutRef = useRef(undefined);
    const [isWillAttemptReconnect, setIsWillAttemptReconnect] = useState(false);
    const [isChannelConnectionFailed, setIsChannelConnectionFailed] = useState(false);
    // Если соединение не было установлено или было прервано ставим таймаут для прекращения попыток подключения
    useEffect(() => {
        if (isWillAttemptReconnect) {
            timeoutRef.current = setTimeout(() => {
                consumerRef.current.disconnect();
                setIsChannelConnectionFailed(true);
            }, reconnectionBreak);
            return () => {
                clearTimeout(timeoutRef.current);
            };
        }
    }, [isWillAttemptReconnect]);
    /**
     * Подключаемся к каналу
     */
    const handleJoinChannel = () => {
        setIsWillAttemptReconnect(true);
        return consumerRef.current.subscriptions.create(channelOptions, {
            /**
             * Вызывается при успешном подключении к каналу.
             */
            connected() {
                clearTimeout(timeoutRef.current);
                setIsWillAttemptReconnect(false);
                setIsChannelConnectionFailed(false);
            },
            /**
             * Вызывается при отключении от канала.
             * @param root0 - Объект с информацией об отключении
             * @param root0.willAttemptReconnect - Флаг, указывающий на попытку переподключения
             */
            disconnected({ willAttemptReconnect } = { willAttemptReconnect: false }) {
                setIsWillAttemptReconnect(!!willAttemptReconnect);
            },
            /**
             * Вызывается при ошибке подключения к каналу.
             */
            rejected() {
                setIsChannelConnectionFailed(true);
            },
            /**
             * Вызывается при получении данных с канала.
             * @param data - Полученные данные.
             */
            received(data) {
                receivedAction(data);
            }
        });
    };
    return { handleJoinChannel, isChannelConnectionFailed };
};
