import { extendTheme } from '@mui/material/styles';
import { colorSchemes } from './core/palette';
import { shadows } from './core/shadows';
import { typography } from './core/typography';
import { components } from './core/components';
/**
 * Создание темы
 */
export const createTheme = () => extendTheme({
    spacing: 4,
    typography,
    colorSchemes,
    shadows,
    components,
    shape: { borderRadius: 8 }
});
