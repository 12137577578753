import React, { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';
import Box from '@mui/material/Box';
// ----------------------------------------------------------------------
export const ScrollbarMain = forwardRef(({ slotProps, children, fillContent, sx, ...other }, ref) => (React.createElement(Box, { component: SimpleBar, scrollableNodeProps: { ref }, clickOnTrack: false, sx: {
        minWidth: 0,
        minHeight: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .simplebar-track.simplebar-vertical': {
            width: theme => theme.spacing(2)
        },
        '& .simplebar-wrapper': slotProps?.wrapper,
        '& .simplebar-content-wrapper': slotProps?.contentWrapper,
        '& .simplebar-scrollbar:before': {
            /**
             * Переопределение
             * @param theme - тема
             */
            backgroundColor: theme => theme.palette.primary.main,
            /**
             * Переопределение
             * @param theme - тема
             */
            width: theme => theme.spacing(1),
            borderRadius: 6
        },
        '& .simplebar-content': {
            ...(fillContent && {
                minHeight: 1,
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column'
            }),
            ...slotProps?.content
        },
        ...sx
    }, ...other }, children)));
