export const mediaQueries = {
    upXs: '@media (min-width:0px)',
    upSm: '@media (min-width:600px)',
    upMd: '@media (min-width:900px)',
    upLg: '@media (min-width:1200px)',
    upXl: '@media (min-width:1536px)'
};
/**
 * Установить шрифт
 * @param fontName - Название шрифта
 */
export function setFont(fontName) {
    return `"${fontName}",-apple-system,BlinkMacSystemFont,"Segoe UI",` +
        'Lato,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
}
/**
 * Адаптивные размеры шрифтов
 * @param root0 - пропсы
 * @param root0.sm - для размер от 600px
 * @param root0.md - для размер от 600px
 * @param root0.lg - для размер от 1200px
 */
export function responsiveFontSizes({ sm, md, lg }) {
    return {
        [mediaQueries.upSm]: { fontSize: sm },
        [mediaQueries.upMd]: { fontSize: md },
        [mediaQueries.upLg]: { fontSize: lg }
    };
}
