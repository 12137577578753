import React from 'react';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useChatItem } from 'actions/chats/useChatItem';
/**
 * Рендерит шапку чата.
 * @param param0 Объект, содержащий id активного чата
 * @param param0.chatId  - активный чат
 * @returns Содержимое шапки
 */
export const ChatHeader = ({ chatId }) => {
    const { palette } = useTheme();
    const { data: chat, isLoading } = useChatItem(chatId);
    /**
     * Рендер header'а активного чата
     * @returns jsx разметка header'а
     */
    const renderContent = () => (React.createElement(Stack, { direction: 'row', overflow: 'hidden', sx: {
            py: 2,
            px: 3,
            gap: 3,
            height: '100%',
            justifyContent: 'space-between'
        } }, isLoading ? (React.createElement(CircularProgress, null)) : (chat && (React.createElement(Stack, { overflow: 'hidden', justifyContent: 'flex-start', height: '100%' },
        React.createElement(Link, { to: '/', target: 'blank', style: { textDecoration: 'none' } },
            React.createElement(Typography, { color: palette.grey[700], fontSize: '18px', fontWeight: '600', noWrap: true, textOverflow: 'ellipsis' }, chat.chat_name)))))));
    return renderContent();
};
