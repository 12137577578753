import React from 'react';
import { classNames } from 'functions/Main';
import { IconTick } from 'svg/common';
const classes = {
    tick: {
        wrapper: 'checkboxContainer__tick',
        label: 'checkbox__tick__label'
    },
    round: {
        wrapper: 'checkboxContainer__round',
        label: 'checkbox__round__label'
    }
};
// Компонент вывода чекбокса
const Checkbox = ({ label, onClick, checked = false, htmlFor, variant = 'tick', isDisabled, dimensionsClassName = 'h-5 w-5', containerClassName = '', labelClass = '' }) => {
    // Вывод круглого чекбокса
    const renderRound = () => (React.createElement("span", { className: classNames('checkbox', {}, ['checkbox__round', label ? '' : dimensionsClassName]), "data-testid": 'Checkbox_round' }));
    // Вывод чекбокса с галочкой
    const renderTick = () => (React.createElement("div", { className: classNames('checkbox checkbox__tick', {}, [dimensionsClassName]), "data-testid": 'Checkbox_tick' },
        React.createElement("div", { className: 'checkbox__tick__iconContainer' },
            React.createElement(IconTick, { className: 'fill-white' }))));
    const renderContent = () => (React.createElement("label", { htmlFor: htmlFor, "data-testid": 'Checkbox_container', className: classNames('text-smaller cursor-pointer checkboxContainer', { isDisabled }, [classes[variant].wrapper, label ? '' : dimensionsClassName, containerClassName]) },
        React.createElement("input", { type: 'checkbox', disabled: isDisabled, id: htmlFor, "data-testid": `checkboxInput_${label}`, name: `${label}`, checked: checked, onChange: onClick, className: 'hidden' }),
        variant === 'tick' ? renderTick() : renderRound(),
        label && (React.createElement("p", { className: classNames('checkbox__label', {}, [classes[variant].label, labelClass]), "data-testid": 'Checkbox_label' }, label))));
    return renderContent();
};
export default Checkbox;
