/**
 * Обработчик для отправки запроса и изменения состояния загрузки
 * @param sendRequest - запрос
 * @param setIsLoading - коллбэк для обработки загрузки
 */
const loadingHandler = async (sendRequest, setIsLoading) => {
    setIsLoading(true);
    if (Array.isArray(sendRequest))
        await Promise.all(sendRequest).finally(() => setIsLoading(false));
    else
        await sendRequest().finally(() => setIsLoading(false));
};
export default loadingHandler;
