import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useAppSelector } from 'redux/hooks';
import { useChatItem } from 'actions/chats/useChatItem';
/**
 * Элемент сообщения
 * @param root0 - пропсы
 * @param root0.message - сообщение
 */
export const ChatsMessengerMessagesItem = ({ message }) => {
    const { data: chat } = useChatItem(message.item_id);
    const { data: userData } = useAppSelector(state => state.user);
    const { user, text, created_at: createdAt } = message;
    const isMyMessage = user.id === userData.id;
    const { palette } = useTheme();
    const renderUserIcon = (React.createElement(Stack, { alignItems: 'center', justifyContent: 'center', height: 40, order: isMyMessage ? 0 : 1, bgcolor: isMyMessage ? palette.green[500] : palette.red[500], width: 40, borderRadius: '50%' },
        React.createElement(Typography, { variant: 'subtitle2', color: palette.common.white, fontWeight: 700 }, isMyMessage ? 'ВЫ' : chat?.chat_name.substring(0, 2))));
    return (React.createElement(Stack, { direction: 'row', gap: 3, justifyContent: isMyMessage ? 'flex-end' : 'unset' },
        React.createElement(Stack, { alignItems: isMyMessage ? 'flex-end' : 'flex-start', order: isMyMessage ? 0 : 1, bgcolor: isMyMessage ? palette.blue[100] : palette.common.white, sx: {
                py: 2.5,
                px: 3,
                gap: 2,
                borderRadius: 1.5,
                boxShadow: '0 4px 4px 0 rgba(180, 180, 180, 0.25)'
            } },
            React.createElement(Typography, null, text),
            React.createElement(Stack, { direction: 'row', gap: 1 },
                React.createElement(Typography, { variant: 'body2', color: palette.grey[600] }, moment(createdAt).format('HH:mm')))),
        renderUserIcon));
};
