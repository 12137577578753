import React from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import { createTheme } from './createTheme';
/**
 * Провайдер для темы
 * @param root0 - пропсы
 * @param root0.children - дочерний элемент
 */
export const ThemeProvider = ({ children }) => {
    const theme = createTheme();
    return (React.createElement(MUIThemeProvider, { theme: theme }, children));
};
