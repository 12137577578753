import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useLocation, useParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import DoctorsMainTab from 'components/doctors/mainTab';
import TabsWrapper from 'helpers/TabsWrapper';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
/**
 * Компонент вывод страницы врача
 * @returns страница врача
 */
const ViewsDoctorsItem = () => {
    const [tabKey, setTabKey] = useQueryParam('tab', StringParam);
    const { state: locationState } = useLocation();
    const { id: doctorId } = useParams();
    const { data, error, refetch, loading } = useFetch(apiUrls.doctors.show(doctorId), 'get');
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    const tabs = [
        {
            key: 'main',
            title: 'Основное',
            /**
             * @returns таб с основной информацией
             */
            renderComponent: () => React.createElement(DoctorsMainTab, { doctorData: data?.doctor, isDataLoading: loading, refetch: refetch })
        }
        // TODO: Эти табы будут реализованы в других задачах
        // {
        //   key: 'education',
        //   title: 'Образование',
        //   /**
        //    * @returns таб с образованием
        //    */
        //   renderComponent: () => <div />
        // },
        // {
        //   key: 'license_and_certificates',
        //   title: 'История сообщений',
        //   /**
        //    * @returns таб с лицензией и сертификатами
        //    */
        //   renderComponent: () => <div />
        // }
    ];
    /**
     * @returns хедер с кнопкой назад и ФИО врача
     */
    const renderHeader = () => (React.createElement("div", { className: 'pt-8 px-8 pb-6' },
        React.createElement(ButtonsBack, { text: locationState?.prevPathName || 'Назад', className: 'mb-1' }),
        React.createElement("h1", null, data?.doctor.full_name)));
    /**
     * @returns основной контент страницы
     */
    const renderContent = () => (React.createElement("div", { className: 'flex-1 flex flex-col' },
        renderHeader(),
        React.createElement(TabsWrapper, { tabs: tabs, tabKey: tabKey, setTabKey: setTabKey })));
    return renderContent();
};
export default ViewsDoctorsItem;
