import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ClinicsSelector from 'components/clinics/Selector';
import Button from 'helpers/Button';
import { IconChat, IconDashboards, IconDoctors, IconExit, IconFeedback, IconMailings, IconPatients, IconSchedules, IconSettings, IconTemplates } from 'svg/menu';
import { IconFile, IconLogo } from 'svg/common';
import { handleErrors } from 'functions/Errors';
import { useAvailableSections } from 'functions/data';
import navigationUrls from 'navigation/routes';
import { removeAppConfig } from 'redux/slices/appConfig';
import { removeUser } from 'redux/slices/user';
import { useAppDispatch } from 'redux/hooks';
import { apiUrls, apiWrapper } from 'config/api';
const mainMenuItems = [
    {
        label: 'Дашборды',
        url: navigationUrls.dashboards.index,
        id: 'dashboards',
        sectionKey: 'dashboards',
        Icon: IconDashboards
    },
    {
        label: 'Рассылки',
        url: navigationUrls.campaigns.index,
        id: 'campaigns',
        sectionKey: 'campaigns',
        Icon: IconMailings,
        alwaysAvailable: true
    },
    {
        label: 'Шаблоны',
        url: navigationUrls.templates.index,
        id: 'templates',
        sectionKey: 'templates',
        Icon: IconTemplates
    },
    {
        label: 'Врачи',
        url: navigationUrls.doctors.index,
        id: 'doctors',
        sectionKey: 'doctors',
        Icon: IconDoctors
    },
    {
        label: 'Пациенты',
        url: navigationUrls.patients.index,
        id: 'patients',
        sectionKey: 'patients',
        Icon: IconPatients
    },
    {
        label: 'Подтверждение записи',
        url: navigationUrls.schedules.index,
        id: 'schedules',
        sectionKey: 'schedules',
        Icon: IconSchedules
    },
    {
        label: 'Очереди',
        url: navigationUrls.queues.list,
        id: 'queues',
        sectionKey: 'queues',
        Icon: IconFile
    },
    {
        label: 'Обратная связь',
        url: navigationUrls.feedback.index,
        id: 'feedback',
        sectionKey: 'campaigns',
        Icon: IconFeedback
    },
    {
        label: 'Настройки',
        url: navigationUrls.settings.index,
        id: 'settings',
        sectionKey: 'settings',
        Icon: IconSettings,
        alwaysAvailable: true
    },
    {
        label: 'Чат',
        url: navigationUrls.chats.index,
        id: 'chats',
        sectionKey: 'chats',
        Icon: IconChat
    }
];
/**
 * Компонент сайдбара
 */
const Sidebar = () => {
    const [unreadCount, setUnreadCount] = useState(0);
    const availableSections = useAvailableSections();
    const availableMenuItems = availableSections.length ?
        mainMenuItems.filter(item => item.alwaysAvailable || availableSections.includes(item.sectionKey)) :
        mainMenuItems;
    const dispatch = useAppDispatch();
    /**
     * Выход из аккаунта
     */
    const logOut = () => {
        dispatch(removeUser());
        dispatch(removeAppConfig());
    };
    /**
     * Получение количества непрочитанных сообщений
     */
    const getUnreadMessagesCount = async () => {
        if (availableSections.includes('chats')) {
            const response = await apiWrapper.get(apiUrls.chats.messages.unreadCount);
            if (!response.ok) {
                throw new Error(handleErrors(response));
            }
            if (response.data?.messages_unread_count)
                setUnreadCount(response.data.messages_unread_count);
        }
        else {
            setUnreadCount(0);
        }
    };
    const renderUnreadMessagesForChatSection = (React.createElement("div", { className: 'bg-primary rounded-2xl flex items-center justify-center h-5.5 px-2' },
        React.createElement("span", { className: 'text-white font-semibold text-smaller' },
            unreadCount,
            "+")));
    useEffect(() => {
        getUnreadMessagesCount();
    }, [availableSections]);
    /**
     * Вывод элемента меню сайдбара
     * @param root0 - пропсы
     * @param root0.label - лейбл
     * @param root0.url - урл раздела
     * @param root0.id - id
     * @param root0.Icon - иконка
     */
    const renderMenuItem = ({ label, url, id, Icon }) => (React.createElement(NavLink, { className: ({ isActive }) => `sidebar__item ${isActive ? 'sidebar__item_active' : ''} gap-3 flex items-center h-10`, to: url, key: `menuItem${id}`, "data-testid": `Sidebar_menuItem_${id}` },
        React.createElement(Icon, { className: 'ml-5' }),
        React.createElement("span", { className: 'sidebar__item__label text-smaller font-semibold' }, label),
        id === 'chats' && !!unreadCount && renderUnreadMessagesForChatSection));
    /**
     * основной контент
     */
    const renderContent = () => (React.createElement("div", { className: 'sidebar flex flex-col justify-between bg-white pt-8', "data-testid": 'Sidebar' },
        React.createElement("div", null,
            React.createElement(IconLogo, { className: 'ml-6 mb-8' }),
            React.createElement("div", { className: 'pl-3.5 pr-3.5 mb-5' },
                React.createElement(ClinicsSelector, null)),
            React.createElement("div", null, availableMenuItems.map(item => renderMenuItem(item)))),
        React.createElement(Button, { className: 'sidebar__exitButton flex items-center mb-10 pl-6 h-10', onClick: logOut, testId: 'Sidebar_exitButton' },
            React.createElement(IconExit, { className: 'mr-3' }),
            React.createElement("span", { className: 'text-smaller font-semibold' }, "\u0412\u044B\u0439\u0442\u0438"))));
    return renderContent();
};
export default Sidebar;
