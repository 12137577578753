import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'config/theme/themeProvider';
import AppRouter from 'components/main/AppRouter';
import ErrorContextProvider from 'helpers/ErrorContextProvider';
import { persistor, setupStore } from 'redux/store';
const queryClient = new QueryClient();
/**
 * Главный компонент приложения
 */
const MainApp = () => (React.createElement("div", { className: 'flex m-auto h-full' },
    React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(ThemeProvider, null,
            React.createElement(Suspense, { fallback: null },
                React.createElement(Provider, { store: setupStore },
                    React.createElement(PersistGate, { loading: null, persistor: persistor },
                        React.createElement(ErrorContextProvider, null,
                            React.createElement(SnackbarProvider, { maxSnack: 3, anchorOrigin: { horizontal: 'right', vertical: 'top' } },
                                React.createElement(AppRouter, null))))))))));
export default MainApp;
