import * as React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import MainAppLayout from 'main/AppLayout';
import ProtectedRoute from 'main/ProtectedRoute';
import ViewsCampaignsHistory from 'views/campaigns/Item';
import ViewsCampaignsIndex from 'views/campaigns/Index';
import ViewsCampaignsLanding from 'views/campaigns/Landing';
import ViewsCampaignsMessagesItem from 'views/campaigns/messages/Item';
import ViewsChats from 'views/Chats';
import ViewsDashboardsCategories from 'views/dashboards/Categories';
import ViewsDashboardsCommon from 'views/dashboards/Common';
import ViewsDashboardsIndex from 'views/dashboards/Index';
import ViewsDashboardsSemenovDetailedStatistic from 'views/dashboards/semenov/DetailedStatistic';
import ViewsDashboardsSemenovIndex from 'views/dashboards/semenov/Index';
import ViewsDashboardsSemenovMetricDescription from 'views/dashboards/semenov/MetricDescription';
import ViewsDoctorsIndex from 'views/doctors/Index';
import ViewsDoctorsItem from 'views/doctors/Item';
import ViewsMainNotFound from 'views/main/NotFound';
import ViewsPatientsIndex from 'views/patients/Index';
import ViewsPatientsItem from 'views/patients/Item';
import ViewsQueuesCampaignTrigger from 'views/queues/CampaignTrigger';
import ViewsQueuesList from 'views/queues/List';
import ViewsQueuesTreatScenario from 'views/queues/TreatScenario';
import ViewsSchedulesIndex from 'views/schedules/Index';
import ViewsSettingsIndex from 'views/settings/Index';
import ViewsSettingsServicesItem from 'views/settings/services/Item';
import ViewsSettingsTreatScenariosItem from 'views/settings/treatScenarios/Item';
import ViewsSettingsTreatScenariosStage from 'views/settings/treatScenarios/Stage';
import ViewsTemplatesEditor from 'views/templates/Editor';
import ViewsTemplatesIndex from 'views/templates/Index';
import navigationUrls from 'navigation/routes';
import ViewsFeedbackIndex from 'views/feedback/Index';
export const appRouter = (initialRoute = navigationUrls.campaigns.index) => [
    {
        element: React.createElement(MainAppLayout, { withSidebar: true }),
        children: [
            {
                path: 'campaigns',
                children: [
                    {
                        index: true,
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsCampaignsIndex, null), section: 'campaigns', redirectTo: navigationUrls.campaigns.landing })
                    },
                    {
                        path: ':campaignId',
                        children: [
                            {
                                index: true,
                                element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsCampaignsHistory, null), section: 'campaigns' })
                            },
                            {
                                path: 'messages/:messageId',
                                element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsCampaignsMessagesItem, null), section: 'campaigns' })
                            }
                        ]
                    },
                    {
                        path: 'landing',
                        element: React.createElement(ViewsCampaignsLanding, null)
                    }
                ]
            },
            {
                path: 'chats',
                element: React.createElement(ViewsChats, null)
            },
            {
                path: 'dashboards',
                children: [
                    {
                        index: true,
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsDashboardsIndex, null), section: 'dashboards' })
                    },
                    {
                        path: 'categories',
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsDashboardsCategories, null), section: 'dashboards' })
                    },
                    {
                        path: 'common',
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsDashboardsCommon, null), section: 'dashboards' })
                    },
                    {
                        path: 'doctors',
                        children: [
                            {
                                index: true,
                                element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsDashboardsSemenovIndex, null), section: 'dashboards' })
                            },
                            {
                                path: 'detailedStatistic',
                                element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsDashboardsSemenovDetailedStatistic, null), section: 'dashboards' })
                            },
                            {
                                path: 'metricDescription',
                                element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsDashboardsSemenovMetricDescription, null), section: 'dashboards' })
                            }
                        ]
                    }
                ]
            },
            {
                path: 'doctors',
                children: [
                    {
                        index: true,
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsDoctorsIndex, null), section: 'doctors' })
                    },
                    {
                        path: ':id',
                        element: React.createElement(ViewsDoctorsItem, null)
                    }
                ]
            },
            {
                path: 'patients',
                children: [
                    {
                        index: true,
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsPatientsIndex, null), section: 'patients' })
                    },
                    {
                        path: ':id',
                        element: React.createElement(ViewsPatientsItem, null)
                    }
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        index: true,
                        element: React.createElement(ViewsSettingsIndex, null)
                    },
                    {
                        path: 'serviceCategories/:categoryId',
                        element: React.createElement(ViewsSettingsServicesItem, null)
                    },
                    {
                        path: 'treatScenarios/:scenarioId',
                        children: [
                            {
                                index: true,
                                element: React.createElement(ViewsSettingsTreatScenariosItem, null)
                            },
                            {
                                path: 'stage/:stageId',
                                element: React.createElement(ViewsSettingsTreatScenariosStage, null)
                            }
                        ]
                    }
                ]
            },
            {
                path: 'schedules',
                element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsSchedulesIndex, null), section: 'schedules' })
            },
            {
                path: 'templates',
                children: [
                    {
                        index: true,
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsTemplatesIndex, null), section: 'templates' })
                    },
                    {
                        path: 'editor/:id',
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsTemplatesEditor, null), section: 'templates' })
                    }
                ]
            },
            {
                path: 'sectionUnavailable',
                element: React.createElement(ViewsMainNotFound, { text: '\u0420\u0430\u0437\u0434\u0435\u043B \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u0435\u043D' })
            },
            {
                path: 'queues',
                children: [
                    {
                        index: true,
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsQueuesList, null), section: 'queues' })
                    },
                    {
                        path: 'campaignTrigger/:triggerId',
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsQueuesCampaignTrigger, null), section: 'queues' })
                    },
                    {
                        path: 'treatScenario/:scenarioId',
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsQueuesTreatScenario, null), section: 'queues' })
                    }
                ]
            },
            {
                path: 'feedback',
                children: [
                    {
                        index: true,
                        element: React.createElement(ProtectedRoute, { component: React.createElement(ViewsFeedbackIndex, null), section: 'campaigns' })
                    }
                ]
            },
            {
                path: '/',
                element: React.createElement(Navigate, { to: initialRoute, replace: true })
            },
            {
                path: '*',
                element: React.createElement(ViewsMainNotFound, null)
            }
        ]
    }
];
export const router = (initialRoute = navigationUrls.campaigns.index) => createBrowserRouter(appRouter(initialRoute));
