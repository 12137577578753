import React, { forwardRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { ScrollbarMain } from './Main';
// Обработка скролла в списке навигации организаций чатов
export const ScrollWithHandler = forwardRef(({ children, handleScroll, ...otherProps }, ref) => {
    useEffect(() => {
        const scrollEl = ref.current;
        const debouncedScrollHandler = debounce(handleScroll, 300);
        if (scrollEl) {
            scrollEl.addEventListener('scroll', debouncedScrollHandler);
            return () => scrollEl.removeEventListener('scroll', debouncedScrollHandler);
        }
    }, [ref, handleScroll]);
    /**
     * Основной контент
     */
    const renderContent = () => (React.createElement(ScrollbarMain, { ...otherProps, scrollableNodeProps: { ref } }, children));
    return renderContent();
});
