import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ButtonsBack from 'components/buttons/Back';
import PatientsShowHeaderInfo from 'components/patients/show/header/Info';
import PatientsShowHeaderSettings from 'components/patients/show/header/Settings';
import useFetch from 'functions/Api';
import { useShowError } from 'functions/Errors';
import { apiUrls } from 'config/api';
/**
 * Компонент шапки страницы пациента
 */
const PatientsShowHeaderIndex = () => {
    const { state: locationState } = useLocation();
    const { id: clientId } = useParams();
    const { data, error } = useFetch(apiUrls.clients.show(clientId), 'get');
    const setError = useShowError();
    useEffect(() => {
        if (error)
            setError(error);
    }, [error]);
    /**
     * Вывод основного контента
     */
    const renderContent = () => (React.createElement("div", { className: 'p-8', "data-testid": 'PatientsShowHeaderIndex' },
        React.createElement(ButtonsBack, { text: locationState?.prevPathName || 'Назад', className: 'mb-1' }),
        React.createElement("h1", { className: 'h-9.5 mb-6' }, data?.client.name),
        React.createElement("div", { className: 'flex gap-12' },
            React.createElement(PatientsShowHeaderInfo, { data: data }),
            data && (React.createElement(PatientsShowHeaderSettings, { isNotificationsAllowedInitial: data.client.notifications_allowed, isHaveMobileAccess: data.client.mobile_access })))));
    return renderContent();
};
export default PatientsShowHeaderIndex;
