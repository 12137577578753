import React, { useCallback, useEffect, useRef } from 'react';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { ScrollWithHandler } from 'helpers/scrollbar/WithHandler';
import { useChats } from 'actions/chats/useChats';
import { useCollapseNav } from 'functions/Chats';
import { ToggleButton } from '../ToggleButton';
import { ChatsNavigationItem } from './Item';
import { ChatsNavigationTabLabel } from './TabLabel';
const NAV_WIDTH = 330;
export const CHAT_TYPES = {
    unassigned: 'unassigned',
    operators: 'operators',
    archive: 'archive'
};
const chatTabs = {
    [CHAT_TYPES.unassigned]: React.createElement(ForumOutlinedIcon, null),
    [CHAT_TYPES.operators]: React.createElement(PeopleAltOutlinedIcon, null),
    [CHAT_TYPES.archive]: React.createElement(ArchiveOutlinedIcon, null)
};
/**
 * Список элементов блока навигации чата
 */
export const ChatsNavigationList = () => {
    const { chats, isLoading, isLoadingAddItems, increasePage } = useChats();
    const [activeTab, setActiveTab] = useQueryParam('tab', StringParam);
    const collapseNav = useCollapseNav();
    const desktopScrollableNodeRef = useRef(null);
    const mobileScrollableNodeRef = useRef(null);
    const theme = useTheme();
    const { openMobile, onOpenMobile, onCloseMobile } = collapseNav;
    const handleScroll = useCallback((event) => {
        const el = event.target;
        if (el.scrollHeight - el.scrollTop === el.clientHeight) {
            increasePage();
        }
    }, [increasePage, openMobile]);
    const renderLoading = (React.createElement(Box, { flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' },
        React.createElement(CircularProgress, null)));
    useEffect(() => {
        if (!activeTab) {
            setActiveTab(CHAT_TYPES.unassigned);
        }
    }, [activeTab]);
    const renderTabsWithLabel = (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { value: activeTab || CHAT_TYPES.unassigned, onChange: (_, value) => setActiveTab(value), sx: {
                minHeight: 40,
                borderTop: `1px solid ${theme.palette.grey[200]}`,
                '& [role="tablist"]': {
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    pl: 5,
                    gap: 5
                }
            }, TabIndicatorProps: { style: { display: 'none' } } }, Object.entries(chatTabs).map(([tab, icon]) => (React.createElement(Tab, { sx: {
                p: 0, minWidth: 30, minHeight: 30, borderRadius: '50%'
            }, value: tab, key: tab, icon: icon })))),
        React.createElement(ChatsNavigationTabLabel, { isChatsNotEmpty: chats.length > 0 })));
    const renderList = (chats.length > 0 && (React.createElement("nav", null,
        React.createElement(Box, { component: 'ul', p: 0 }, chats.map(chat => (React.createElement(ChatsNavigationItem, { key: chat.id, chatData: chat, onCloseMobile: onCloseMobile })))))));
    /**
     * Рендер основного контента
     * @param scrollableNodeRef - ссылка на ноду контейнера со скроллом
     */
    const renderContent = (scrollableNodeRef) => (React.createElement(React.Fragment, null,
        isLoading ? (renderLoading) : (React.createElement(ScrollWithHandler, { handleScroll: handleScroll, ref: scrollableNodeRef },
            renderTabsWithLabel,
            renderList)),
        isLoadingAddItems && (React.createElement(Box, { sx: {
                position: 'absolute',
                bottom: 16,
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            } },
            React.createElement(CircularProgress, null)))));
    return (React.createElement(React.Fragment, null,
        React.createElement(ToggleButton, { onClick: onOpenMobile, sx: { display: { lg: 'none' } } },
            React.createElement(GroupsIcon, { sx: { width: 16 } })),
        React.createElement(Stack, { sx: {
                minHeight: 0,
                flex: '1 1 auto',
                width: NAV_WIDTH,
                position: 'relative',
                display: { xs: 'none', lg: 'flex' },
                borderRight: `solid 1px ${theme.palette.grey[100]}`,
                transition: theme.transitions.create(['width'], { duration: theme.transitions.duration.shorter })
            } }, renderContent(desktopScrollableNodeRef)),
        React.createElement(Drawer, { open: openMobile, onClose: onCloseMobile, slotProps: { backdrop: { invisible: true } }, PaperProps: { sx: { width: NAV_WIDTH } } }, renderContent(mobileScrollableNodeRef))));
};
