import { handleErrors } from 'functions/Errors';
import { apiUrls, apiWrapper } from 'config/api';
/**
 * Сделать сообщение прочитанным
 * @param chatId - id чата
 * @param root0 - пропсы
 * @param root0.onError - коллбэк для обработки ошибки
 * @param root0.onRead - коллбэк при успешной отправке запроса
 */
export const markMessagesRead = async (chatId, { onError, onRead = () => { } }) => {
    const response = await apiWrapper.patch(apiUrls.chats.messages.markRead(chatId));
    if (response.ok) {
        onRead();
    }
    else {
        onError(handleErrors(response));
    }
};
