import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, InputBase } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useSnackbar } from 'notistack';
/**
 * Поле ввода для мессенджера чатов
 * @param root0 - пропсы
 * @param root0.message - сообщение
 * @param root0.setMessage - функция изменения сообщения
 * @param root0.onSend - обработчик отправки сообщения
 * @param root0.isDisabled - заблокирован ли ввод
 */
export const ChatsMessengerInput = ({ message, setMessage, onSend, isDisabled }) => {
    const inputRef = useRef(null);
    const [isShouldRefocus, setIsShouldRefocus] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleChangeMessage = useCallback((event) => {
        setMessage(event.target.value);
    }, []);
    /**
     * Отправить сообщение
     */
    const sendMessage = async () => {
        await onSend();
        setIsShouldRefocus(true);
    };
    const handleSendMessage = useCallback(async (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (!message) {
                return enqueueSnackbar('Сообщение не может быть пустым', { variant: 'error' });
            }
            await sendMessage();
        }
    }, [onSend, message]);
    useEffect(() => {
        if (isShouldRefocus && !isDisabled) {
            inputRef.current?.focus();
            setIsShouldRefocus(false);
        }
    }, [isDisabled, isShouldRefocus]);
    /**
     * Основной контент
     */
    const renderContent = () => (React.createElement(Box, { sx: {
            display: 'flex',
            position: 'relative',
            alignItems: 'end',
            px: 5,
            /**
             * Стили для верхней границы
             * @param theme - тема
             */
            borderTop: theme => `solid 1px ${theme.palette.grey[100]}`
        } },
        React.createElement(InputBase, { value: message, onKeyDown: handleSendMessage, onChange: handleChangeMessage, placeholder: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435...', multiline: true, maxRows: 6, disabled: isDisabled, inputRef: inputRef, sx: {
                flex: 1,
                px: 1,
                minHeight: 56,
                flexShrink: 0,
                fontSize: 16
            } }),
        React.createElement(IconButton, { sx: { mb: 2 }, disabled: isDisabled || !message, onClick: sendMessage },
            React.createElement(SendIcon, { color: 'primary', sx: { width: 22, height: 22 } }))));
    return renderContent();
};
