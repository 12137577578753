import React, { forwardRef } from 'react';
import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import ChatBackground from 'images/chat/Background.png?as=webp';
import { ScrollWithHandler } from 'helpers/scrollbar/WithHandler';
import { groupMessagesByDate } from 'functions/Chats';
import ChatPlaceholder from 'images/chat/Placeholder.png?as=webp';
import { ChatsMessengerMessagesItem } from './item';
export const ChatsMessengerMessages = forwardRef(({ messages, isLoading, isAddLoading, handleScroll }, ref) => {
    const { palette } = useTheme();
    const renderLoader = (React.createElement(Box, { sx: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        } },
        React.createElement(CircularProgress, null)));
    const renderAddLoader = (React.createElement(Box, { sx: {
            position: 'absolute',
            top: 24,
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        } },
        React.createElement(CircularProgress, null)));
    const renderNoMessagesPlaceholder = (React.createElement(Stack, { sx: {
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        } },
        React.createElement(Box, { component: 'img', mb: 6, width: 106, height: 64, src: ChatPlaceholder, alt: 'Chat placeholder' }),
        React.createElement(Typography, { fontWeight: 600, variant: 'h5', mb: 2, color: palette.grey[500] }, "\u0412 \u0432\u0430\u0448\u0435\u043C \u0447\u0430\u0442\u0435 \u043F\u043E\u043A\u0430 \u043D\u0435\u0442 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439"),
        React.createElement(Typography, { fontWeight: 500, variant: 'subtitle1', color: palette.grey[400] }, "\u041D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u0447\u0442\u043E-\u043D\u0438\u0431\u0443\u0434\u044C \u0434\u043B\u044F \u043D\u0430\u0447\u0430\u043B\u0430 \u0434\u0438\u0430\u043B\u043E\u0433\u0430")));
    /**
     * Рендерит сообщения в чате, группируя их по дате.  Если сообщений нет, отображает сообщение об отсутствии сообщений.
     * @returns JSX-элемент, представляющий сообщения чата или сообщение об отсутствии сообщений.
     */
    const renderMessages = () => {
        if (messages.length > 0) {
            const groupedMessages = groupMessagesByDate(messages);
            return (React.createElement(React.Fragment, null,
                isAddLoading && renderAddLoader,
                Object.entries(groupedMessages).map(([date, messagesForDate]) => (React.createElement(Stack, { gap: 3, position: 'relative', height: 'unset', key: date },
                    React.createElement(Stack, { justifyContent: 'center', alignItems: 'center' },
                        React.createElement(Typography, { variant: 'subtitle2', fontWeight: '800', color: palette.blue[700] }, date)),
                    messagesForDate.map((item) => (React.createElement(ChatsMessengerMessagesItem, { message: item, key: item.id }))))))));
        }
        return renderNoMessagesPlaceholder;
    };
    /**
     * Рендерит основной контент компонента, включая индикатор загрузки, сообщения чата или сообщение об отсутствии сообщений.
     *  Использует ScrollWithHandler для обработки прокрутки.
     * @returns JSX-элемент, представляющий основной контент компонента.
     */
    const renderContent = () => (React.createElement(ScrollWithHandler, { ref: ref, sx: {
            py: 6,
            px: 5,
            backgroundImage: `url(${ChatBackground})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }, handleScroll: handleScroll, slotProps: {
            contentWrapper: { display: 'grid' },
            content: {
                display: 'flex',
                flexDirection: 'column-reverse',
                gap: 3,
                height: messages.length > 0 ? 'unset' : '100%',
                position: 'relative'
            }
        } }, isLoading ? renderLoader : renderMessages()));
    return renderContent();
});
