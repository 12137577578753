import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apiUrls } from 'config/api';
import { getChatItem } from './getChatItem';
/**
 * Получение информации по конкретному чату
 * @param chatId id активного чата
 * @returns статус запроса и полученную инфу о чате
 */
export const useChatItem = (chatId) => {
    const { enqueueSnackbar } = useSnackbar();
    const { data, error, isFetching } = useQuery({
        queryKey: [apiUrls.chats.item(chatId)],
        queryFn: getChatItem,
        refetchOnWindowFocus: false,
        gcTime: 0
    });
    // Обработка ошибки получения профиля
    useEffect(() => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }, [error]);
    // Данные доступные из хука
    const memoizedValue = useMemo(() => ({
        data,
        isLoading: isFetching
    }), [data, isFetching]);
    return memoizedValue;
};
