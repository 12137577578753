import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Checkbox from 'helpers/Checkbox';
import { handleErrors, useShowError } from 'functions/Errors';
import { loadingHandler } from 'functions/data';
import { apiUrls, apiWrapper } from 'config/api';
/**
 * Чекбокс с переключением доступа у мобильному приложению
 * @param root0 Пропсы
 * @param root0.isHaveMobileAccess Начальное значение - есть ли доступ
 */
const PatientsShowHeaderMobileAccess = ({ isHaveMobileAccess }) => {
    const { id: clientId } = useParams();
    const [isAccessed, setIsAccessed] = useState(isHaveMobileAccess);
    const [isLoading, setIsLoading] = useState(false);
    const setError = useShowError();
    /**
     * Запрос на переключение доступа к мобильному приложению
     */
    const requestToggleMobileAccess = async () => {
        const response = await apiWrapper.patch(apiUrls.clients.changeMobileAccess(clientId));
        if (response.ok) {
            setIsAccessed(prev => !prev);
        }
        else {
            setError(handleErrors(response));
        }
    };
    /**
     * Обработка нажатия на чекбокс
     */
    const onClick = () => {
        loadingHandler(requestToggleMobileAccess, setIsLoading);
    };
    /**
     * Вывод основного контента
     */
    const renderContent = () => (React.createElement("div", { className: 'flex mb-2' },
        React.createElement(Checkbox, { checked: isAccessed, onClick: onClick, isDisabled: isLoading, label: '\u0414\u043E\u0441\u0442\u0443\u043F \u043A \u043C\u043E\u0431\u0438\u043B\u044C\u043D\u043E\u043C\u0443 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u044E', labelClass: 'text-big' })));
    return renderContent();
};
export default PatientsShowHeaderMobileAccess;
