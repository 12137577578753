import { handleErrors } from 'functions/Errors';
import { apiWrapper } from 'config/api';
/**
 * Получение информации об конкретном чате по id
 * @param param0 Объект, содержащий ключ запроса
 * @param param0.queryKey Массив, где первый элемент — строка с URL запроса
 * @returns Полученную с сервера информацию о чате, либо пустой объект
 */
export const getChatItem = async ({ queryKey }) => {
    const queryUrl = queryKey[0];
    const response = await apiWrapper.get(queryUrl);
    if (!response.ok) {
        throw new Error(handleErrors(response));
    }
    return response.data?.chat || {};
};
