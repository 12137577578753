import { ButtonBase, styled } from '@mui/material';
export const ToggleButton = styled(ButtonBase)(({ theme }) => ({
    top: 84,
    left: 0,
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    borderRadius: '0 12px 12px 0',
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create(['all'], { duration: theme.transitions.duration.shorter }),
    '&:hover': { backgroundColor: theme.palette.info.dark }
}));
