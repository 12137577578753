import React, { useState } from 'react';
import Button from 'helpers/Button';
import ButtonsIcon from 'components/buttons/Icon';
import { IconBasket, IconPen, IconReplace } from 'svg/common';
import ImageDoctorsAvatarPlaceholderWebp from 'images/doctors/AvatarPlaceholder.png?as=webp';
import { handleErrors, useShowError } from 'functions/Errors';
import { apiUrls, apiWrapper } from 'config/api';
import { classNames } from 'functions/Main';
/**
 * Компонент отрисовки и управления аватаркой
 * @param root0 - пропсы
 * @param root0.doctorId - id врача
 * @param root0.avatarUrl - урл аватара
 * @param root0.refetch - функция для повторного запроса данных
 * @param root0.isDataSaving - происходит ли сейчас сохранение данных
 * @param root0.setIsDataSaving - установить состояния загрузки данных
 * @returns - секцию аватара
 */
const DoctorsMainTabAvatar = ({ doctorId, avatarUrl, refetch, isDataSaving, setIsDataSaving }) => {
    const [isEditImage, setIsEditImage] = useState(false);
    const setError = useShowError();
    /**
     * Загрузка аватара
     * @param file - файл аватара
     */
    const uploadAvatar = async (file) => {
        setIsDataSaving(true);
        const formData = new FormData();
        formData.append('doctor[avatar]', file);
        const response = await apiWrapper.patch(apiUrls.doctors.show(doctorId), formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (response.ok) {
            refetch();
            setIsEditImage(false);
        }
        else {
            setError(handleErrors(response));
        }
        setIsDataSaving(false);
    };
    /**
     * Обработка изменения аватара
     * @param event - ивент
     */
    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file)
            uploadAvatar(file);
    };
    /**
     * Удаление аватара
     */
    const deleteAvatar = async () => {
        setIsDataSaving(true);
        const response = await apiWrapper.patch(apiUrls.doctors.show(doctorId), { doctor: { avatar: null } });
        if (response.ok) {
            refetch();
            setIsEditImage(false);
        }
        else {
            setError(handleErrors(response));
        }
        setIsDataSaving(false);
    };
    /**
     * Отрисовка окна для редактирования/удаления аватара
     * @returns окно для редактирования/удаления аватара
     */
    const renderEditImageWindow = () => (React.createElement("div", { className: 'absolute rounded-lg top-0 w-full p-4 flex flex-col gap-4\n    bg-white text-sm font-bold text-gray-85 shadow-[0_0_2px_0_rgba(145,158,171,0.2)]' },
        React.createElement(Button, { disabled: isDataSaving },
            React.createElement("label", { htmlFor: 'upload-avatar', className: 'cursor-pointer flex items-center gap-2.5' },
                React.createElement(IconReplace, { className: 'h-5 w-5' }),
                React.createElement("span", null, "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0444\u043E\u0442\u043E\u0433\u0440\u0430\u0444\u0438\u044E")),
            React.createElement("input", { id: 'upload-avatar', type: 'file', className: 'hidden', accept: '.jpg,.png', onChange: handleFileChange })),
        React.createElement(Button, { onClick: deleteAvatar, disabled: !avatarUrl || isDataSaving },
            React.createElement("div", { className: 'flex items-center gap-2.5' },
                React.createElement(IconBasket, { className: 'h-5 w-5' }),
                React.createElement("span", null, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0444\u043E\u0442\u043E\u0433\u0440\u0430\u0444\u0438\u044E")))));
    return (React.createElement("div", { className: 'flex flex-col gap-2 w-[236px]' },
        React.createElement("div", { className: 'relative' },
            React.createElement("img", { src: avatarUrl || ImageDoctorsAvatarPlaceholderWebp, className: 'aspect-3/4 object-cover rounded-lg bg-gray-15', width: 236, height: 314, alt: '\u0424\u043E\u0442\u043E \u0432\u0440\u0430\u0447\u0430' }),
            React.createElement(ButtonsIcon, { className: classNames('absolute right-2 bottom-2 bg-white flex items-center justify-center w-13 h-9 rounded-lg', { 'bg-blue': isEditImage }), onClick: () => setIsEditImage(!isEditImage) },
                React.createElement(IconPen, null))),
        React.createElement("div", { className: 'relative' },
            React.createElement("p", { className: 'flex flex-col text-smallest text-gray-main gap-1' },
                "\u0424\u043E\u0440\u043C\u0430\u0442 \u0437\u0430\u0433\u0440\u0443\u0436\u0430\u0435\u043C\u044B\u0445 \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u0439 \u2014 jpg, png",
                React.createElement("br", null),
                "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u044B\u0439 \u0440\u0430\u0437\u043C\u0435\u0440 \u0437\u0430\u0433\u0440\u0443\u0436\u0430\u0435\u043C\u043E\u0433\u043E \u0444\u0430\u0439\u043B\u0430 \u2014 7 \u041C\u0431",
                React.createElement("br", null),
                "\u0421\u043E\u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u0435 \u0441\u0442\u043E\u0440\u043E\u043D \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F 3 \u043D\u0430 4"),
            isEditImage && renderEditImageWindow())));
};
export default DoctorsMainTabAvatar;
