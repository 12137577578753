import { responsiveFontSizes, setFont } from '../styles/utils';
export const typography = {
    fontFamily: setFont('Lato'),
    h1: {
        fontWeight: 500,
        lineHeight: 50 / 42,
        fontSize: '32px',
        ...responsiveFontSizes({ sm: '32px', md: '42px', lg: '42px' })
    },
    h2: {
        fontWeight: 500,
        lineHeight: 48 / 36,
        fontSize: '30px',
        ...responsiveFontSizes({ sm: '30px', md: '36px', lg: '36px' })
    },
    h5: {
        fontWeight: 500,
        lineHeight: 29 / 25,
        fontSize: '25px'
    },
    subtitle1: {
        lineHeight: 21 / 18,
        fontSize: 18
    },
    subtitle2: {
        lineHeight: 19 / 16,
        fontSize: 16
    },
    body1: {
        lineHeight: 18 / 14,
        fontSize: 14
    },
    body2: {
        lineHeight: 16 / 12,
        fontSize: 12
    }
};
