import React from 'react';
import { Box, Typography } from '@mui/material';
/**
 * Главный лэйаут
 * @param root0 - пропсы
 * @param root0.title - заголовок
 * @param root0.children - дочерний элемент
 * @param root0.sx - стили
 */
export const LayoutsMain = ({ title, children, sx, ...otherProps }) => (React.createElement(Box, { ...otherProps, sx: {
        /**
         * Установка цвета фона
         * @param theme - тема
         */
        backgroundColor: theme => theme.palette.grey[50],
        height: '100%',
        width: '100%',
        p: 6,
        display: 'flex',
        flexDirection: 'column',
        ...sx
    } },
    title && (React.createElement(Typography, { variant: 'h1', sx: {
            color: theme => theme.palette.info.dark, mb: 5
        } }, title)),
    children));
